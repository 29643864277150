import { Container, Col, Row } from '@geberit/gdds';

// styles
import styles from './product-slider.module.scss';

// types
import type { ProductSlideTitleProps } from './product-slider.types';

// components
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useThemeName } from 'utils/hooks/use-theme';

export default function ProductSlideTitle({
  title,
  subtitle,
  emphasize,
  colorVariant,
  link,
}: Readonly<ProductSlideTitleProps>) {
  const themeName = useThemeName();
  const isMobile = useIsMobile();
  const isLinkInverted = colorVariant === 'white' && !isMobile && link?.showArrow;

  return (
    <Container maxContentWidth="78rem" className={styles.slideTile}>
      {(title || subtitle) && (
        <Row>
          <Col className={styles[colorVariant]}>
            <Headline
              title={title}
              subtitle={subtitle}
              titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h1)}
              subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, Formats.h1)}
              tag={Formats.h2}
              format={Formats.h1}
              titlePreviewId="#st_title"
              subtitlePreviewId="#st_subtitle"
            />
          </Col>
        </Row>
      )}
      {link && (
        <Row>
          <Col className={classNameBuilder(styles[colorVariant], styles.link)}>
            <CmsLink
              link={link}
              standardFontSize={false}
              alignByContent={!link.showArrow && 'left'}
              stylingType={isLinkInverted ? 'inverted' : 'secondary'}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}
