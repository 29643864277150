import { forwardRef } from 'react';
import { Button } from '@geberit/gdds';

// styles
import styles from './product-slider.module.scss';

// types
import type { ProductSliderViewProps } from './product-slider.types';

// components
import ProductSlide from './product-slide';
import ProductSlideTitle from './product-slide-title';
import ProductSlideHandler from './product-slide-handler';
import Overlay from './overlay/overlay';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { useSectionId } from 'utils/hooks/use-section-id';
import { useIsMobile } from 'components/App/SizeProvider';

function ProductSliderView(
  {
    behind,
    infront,
    overlay,
    contentIndex,
    colorVariant,
    titleInAnchor,

    setFixedOverlayOpen,
    closeActiveHotspotOverlay,
    lastHotspot,

    ...props
  }: ProductSliderViewProps,
  sliderContainerRef: React.MutableRefObject<HTMLDivElement | null>,
) {
  const { title, link, emphasize, subtitle } = props;
  const hasOverlay = overlay.title || overlay.subtitle || overlay.text;
  const sectionId = useSectionId(title, '', titleInAnchor, contentIndex);
  const isMobile = useIsMobile();

  return (
    <div className={classNameBuilder(styles.productSlider, styles[colorVariant])} id={sectionId}>
      {/* fixed overlay without hotspot */}
      {hasOverlay && (
        <Overlay
          title={overlay.title}
          subtitle={overlay.subtitle}
          emphasize={overlay.emphasize}
          text={overlay.text}
          onCloseClick={() => setFixedOverlayOpen(false)}
          isFixedOverlay
        />
      )}
      {/* overlay for hotspots */}
      {!isMobile && lastHotspot && (
        <Overlay
          title={lastHotspot.description.headline}
          subtitle={lastHotspot.description.subline}
          text={lastHotspot.description.text}
          emphasize="title"
          onCloseClick={closeActiveHotspotOverlay}
          overlayPosition={lastHotspot.overlayPosition}
          sliderContainerRef={sliderContainerRef}
          className={styles.overlay}
          pictureObject={lastHotspot.description.pictureObject}
          pictureObjectMobile={lastHotspot.description.pictureObjectMobile}
        />
      )}
      {isMobile && (
        <ProductSlideTitle
          title={title}
          link={link}
          emphasize={emphasize}
          colorVariant={colorVariant}
          subtitle={subtitle}
        />
      )}
      <div className={styles.relative} ref={sliderContainerRef}>
        <ProductSlide
          {...props}
          {...behind}
          lastHotspot={lastHotspot}
          colorScheme={behind.colorVariant}
          colorVariant={colorVariant}
          isBehind
        />
        <ProductSlide
          {...props}
          {...infront}
          lastHotspot={lastHotspot}
          colorScheme={infront.colorVariant}
          colorVariant={colorVariant}
        />

        {/* info icon */}
        {hasOverlay && (
          <Button
            isIcon
            symbol="InfoCircleFullBlue"
            stylingType="flat"
            onClick={() => setFixedOverlayOpen(true)}
            className={styles.buttonInfo}
          />
        )}

        <ProductSlideHandler />
      </div>
    </div>
  );
}

export default forwardRef(ProductSliderView);
