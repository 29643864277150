import { useState, useRef, useEffect, useCallback } from 'react';
import { ResizeObserver as PolyfillResizeObserver } from '@juggle/resize-observer';

// types
import type { ActiveHotspot, Hotspot, ProductSliderProps } from './product-slider.types';

// components
import ProductSliderView from './product-slider-view';
import { useIsMobile } from 'components/App/SizeProvider';

// utils
import { isInViewport } from 'utils/hooks/useLazyLoad';
import { ProductSliderContextProvider } from './context';

export function ProductSlider(props: Readonly<ProductSliderProps>) {
  const [tooltip, setTooltip] = useState<Hotspot | undefined>();
  const [fixedOverlayOpen, setFixedOverlayOpen] = useState(false);
  const [slidePercentage, setSlidePercentage] = useState(0);
  const [sliderContainerWidth, setSliderContainerWidth] = useState(0);
  const sliderContainerRef = useRef<HTMLDivElement | null>(null);

  const prevSlidePosition = useRef<string | undefined>(undefined);
  const hotspotOverlayRef = useRef<HTMLDivElement | null>(null);
  const timeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const animationTimeout = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const slideHandlerRef = useRef<HTMLDivElement | null>(null);
  const buttonOnHold = useRef(false);
  const [lastHotspot, setLastHotspot] = useState<ActiveHotspot>();
  const [hotspotOpen, setHotspotOpen] = useState(false);
  const isMobile = useIsMobile();

  const closeActiveHotspotOverlay = async (movePosition = true) => {
    if (movePosition) {
      await animateOnce();
      setSlidePercentage(parseInt(prevSlidePosition.current || '0'));
      resizeInfront(prevSlidePosition.current || 0);
    }
    setHotspotOpen(false);
    setTooltip(undefined);
  };

  /* touch events */
  const mouseDown = (e: React.SyntheticEvent<HTMLButtonElement | HTMLSpanElement, Event>) => {
    e.preventDefault();

    timeout.current = setTimeout(() => {
      buttonOnHold.current = true;
    }, 150);
  };

  const moveEnd = (e) => {
    const parentIsButton = e.target.closest('button');

    if (!parentIsButton) {
      clearTimeout(timeout.current);
      buttonOnHold.current = false;
    }
  };

  useEffect(() => {
    const touchHandler = (event) => {
      if (buttonOnHold.current) {
        const relativeX = event.pageX ?? event.touches[0].pageX;
        const toleranceX = relativeX > 40 ? relativeX - 40 : relativeX;
        const slidePercent = (toleranceX / sliderContainerWidth) * 100;

        if (slidePercent <= 100 && slidePercent >= 0) {
          resizeInfront(`${slidePercent}%`);
          setSlidePercentage(slidePercent);
        }
      }
    };

    const sliderContainer = sliderContainerRef.current;
    if (sliderContainer) {
      sliderContainer.addEventListener('touchmove', touchHandler);
      sliderContainer.addEventListener('mousemove', touchHandler);
      sliderContainer.addEventListener('mouseup', moveEnd);
      sliderContainer.addEventListener('touchend', moveEnd);

      return () => {
        sliderContainer.removeEventListener('touchmove', touchHandler);
        sliderContainer.removeEventListener('mousemove', touchHandler);
        sliderContainer.removeEventListener('mouseup', moveEnd);
        sliderContainer.removeEventListener('touchend', moveEnd);

        if (timeout.current) clearTimeout(timeout.current);
      };
    }

    return () => {};
  }, [sliderContainerWidth]);
  /* end touch events */

  // animation
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const startAnimate = useCallback(async () => {
    const frontImage = sliderContainerRef.current?.querySelector<HTMLDivElement>('.absolute');
    if (frontImage) {
      frontImage.style.transition = '2s ease';
      if (slideHandlerRef.current) {
        slideHandlerRef.current.style.transition = '2s ease';
      }
    }
    // wait for the transition to be available
    await sleep(100);
  }, []);

  const stopAnimate = () => {
    const frontImage = sliderContainerRef.current?.querySelector<HTMLDivElement>('.absolute');
    if (frontImage) {
      frontImage.style.transition = 'initial';
      if (slideHandlerRef.current) {
        slideHandlerRef.current.style.transition = 'initial';
      }
    }
  };

  const animateOnce = async () => {
    clearTimeout(animationTimeout.current);
    await startAnimate();
    clearTimeout(animationTimeout.current);
    animationTimeout.current = setTimeout(stopAnimate, 1500);
  };

  useEffect(() => {
    const sliderContainer = sliderContainerRef.current;
    if (sliderContainer) {
      const handleAnimation = () => {
        if (isInViewport(sliderContainer, 0 - sliderContainer.offsetHeight / 3)) {
          window.removeEventListener('scroll', handleAnimation);
          window.removeEventListener('resize', handleAnimation);

          runAnimation();
          if (!isMobile) setFixedOverlayOpen(true);
        }
      };

      const runAnimation = () => {
        startAnimate();

        // start animation a bit delayed
        setTimeout(() => {
          resizeInfront('80%');
          setSlidePercentage(80);
          updatePrevPosition();

          // go back to the initial position
          setTimeout(() => {
            resizeInfront('40%');
            setSlidePercentage(40);
            updatePrevPosition();

            // clear css animations
            setTimeout(stopAnimate, 3000);
          }, 3000);
        }, 300);
      };

      window.addEventListener('scroll', handleAnimation);
      window.addEventListener('resize', handleAnimation);
      handleAnimation();

      return () => {
        window.removeEventListener('scroll', handleAnimation);
        window.removeEventListener('resize', handleAnimation);
      };
    }

    return () => {};
  }, [isMobile, sliderContainerRef, startAnimate]);

  const updateSliderContainerWidth = () => {
    setSliderContainerWidth(sliderContainerRef.current?.offsetWidth ?? 0);
  };

  useEffect(() => {
    if (sliderContainerRef.current) {
      updateSliderContainerWidth();

      // observe width of slider
      const ResizeObserver = window.ResizeObserver || PolyfillResizeObserver;
      const observer = new ResizeObserver(updateSliderContainerWidth);
      observer.observe(sliderContainerRef.current);

      return () => {
        observer.disconnect();
      };
    }

    return () => {};
  }, [sliderContainerRef]);

  useEffect(() => {
    if (hotspotOpen && fixedOverlayOpen) {
      setFixedOverlayOpen(false);
    }
  }, [hotspotOpen]);

  useEffect(() => {
    if (hotspotOpen && fixedOverlayOpen) {
      setHotspotOpen(false);
    }
  }, [fixedOverlayOpen]);

  const updatePrevPosition = () => {
    prevSlidePosition.current = slideHandlerRef.current?.style.left;
  };

  const resizeInfront = (width) => {
    if (slideHandlerRef.current) {
      slideHandlerRef.current.style.left = width;
    }
    const absoluteBox = sliderContainerRef.current?.querySelector<HTMLElement>('.absolute');
    if (absoluteBox) {
      absoluteBox.style.width = `calc(${width} + 2.75rem )`;
    }
  };

  const onButtonClick = async (e: React.MouseEvent<HTMLButtonElement>, direction?: string) => {
    const isRight =
      (typeof e?.currentTarget.className === 'string' &&
        e.currentTarget.className.includes('right')) ||
      direction === 'right';

    if (direction) {
      if (!hotspotOpen) {
        updatePrevPosition();
      }
    } else {
      prevSlidePosition.current = undefined;
    }

    if (!buttonOnHold.current) {
      await animateOnce();

      if (isRight) {
        // show left image
        resizeInfront(`calc(100% - 2.75rem)`);
        setSlidePercentage(100);
      } else {
        // show right image
        resizeInfront(`-2.75rem`);
        setSlidePercentage(0);
      }
    }

    clearTimeout(timeout.current);
    buttonOnHold.current = false;
  };

  return (
    <ProductSliderContextProvider
      slidePercentage={slidePercentage}
      fixedOverlayOpen={fixedOverlayOpen}
      hotspotOpen={hotspotOpen}
      setHotspotOpen={setHotspotOpen}
      sliderContainerWidth={sliderContainerWidth}
      slideHandlerRef={slideHandlerRef}
      onButtonClick={onButtonClick}
      onButtonDrag={mouseDown}
      hotspotOverlayRef={hotspotOverlayRef}
      setLastHotspot={setLastHotspot}
      tooltip={tooltip}
      setTooltip={setTooltip}
    >
      <ProductSliderView
        setFixedOverlayOpen={setFixedOverlayOpen}
        closeActiveHotspotOverlay={closeActiveHotspotOverlay}
        lastHotspot={lastHotspot}
        ref={sliderContainerRef}
        // exception: since this component is pure logic we pass down the whole server props
        {...props}
      />
    </ProductSliderContextProvider>
  );
}
