import { useContext, useEffect, useState } from 'react';
import { InnerHtml, Button } from '@geberit/gdds';

// styles
import styles from './overlay.module.scss';

// components
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import { Image } from 'components/image/image';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { responsiveImage } from 'utils/responsiveImage';
import { useThemeName } from 'utils/hooks/use-theme';
import { classNameBuilder } from 'utils/classNameBuilder';
import { ProductSliderContext } from '../context';

interface OverlayProps {
  title: string;
  subtitle: string;
  text: string;
  emphasize: string;
  onCloseClick: (movePosition?: boolean) => void;
  pictureObject?: ImageObject;
  pictureObjectMobile?: ImageObjectMobile;
  sliderContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
  overlayPosition?: string;
  className?: string;
  isFixedOverlay?: boolean;
}

export default function Overlay({
  title,
  subtitle,
  text,
  emphasize,
  onCloseClick,
  pictureObject,
  pictureObjectMobile,
  sliderContainerRef,
  overlayPosition,
  className,
  isFixedOverlay = false,
}: Readonly<OverlayProps>) {
  const isMobile = useIsMobile();
  const [headlineHeight, setHeadlineHeight] = useState(0);
  const imageObj = responsiveImage(isMobile, pictureObjectMobile, pictureObject);
  const themeName = useThemeName();
  const { fixedOverlayOpen, hotspotOpen, slideHandlerRef, hotspotOverlayRef } =
    useContext(ProductSliderContext);
  const open = (isFixedOverlay && fixedOverlayOpen) || hotspotOpen;

  // close overlay if user clicks outside
  useEffect(() => {
    const outsideClick = (e) => {
      const clickedInside =
        hotspotOverlayRef?.current === e.target ||
        hotspotOverlayRef?.current?.contains(e.target) ||
        e.target.tagName === 'circle';

      if (!clickedInside) {
        const clickedArrow =
          slideHandlerRef?.current === e.target || slideHandlerRef?.current?.contains(e.target);
        onCloseClick(!clickedArrow);
      }
    };
    if (open) {
      window.addEventListener('click', outsideClick);
    }

    if (overlayPosition === 'left' && sliderContainerRef && !isMobile) {
      const titleHeight = sliderContainerRef.current
        ?.querySelector('h2')
        ?.getBoundingClientRect().height;

      // 30px titlemargin + 80px sectionmargin, default 80px section margin
      const mergedHeight = titleHeight ? titleHeight + 30 + 80 : 80;
      setHeadlineHeight(mergedHeight);
    } else {
      setHeadlineHeight(0);
    }

    return () => {
      window.removeEventListener('click', outsideClick);
    };
  }, [open, overlayPosition]);

  return (
    <div
      className={classNameBuilder(
        styles.overlay,
        !open && styles.closed,
        overlayPosition && styles[overlayPosition],
        className,
      )}
      ref={hotspotOverlayRef}
      {...(headlineHeight ? { style: { top: headlineHeight } } : {})}
    >
      <div className={styles.contentWrapper}>
        <Button
          stylingType="flat"
          isIcon
          symbol="Close"
          onClick={() => onCloseClick()}
          className={styles.closeButton}
        />
        <div>
          <Headline
            title={title}
            subtitle={subtitle}
            titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h3)}
            subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, Formats.h3)}
            format={Formats.h3}
          />
        </div>
        {text && <InnerHtml as="div" content={text} />}
      </div>
      {imageObj && <Image data={imageObj} lazy={false} />}
    </div>
  );
}
