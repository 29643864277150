import { useContext } from 'react';

// styles
import styles from './product-slider.module.scss';

// types
import type { ProductSlideProps } from './product-slider.types';

// components
import { Image } from 'components/image/image';
import ProductSlideTitle from './product-slide-title';
import HotspotsWrapper from './hotspots-wrapper';
import FadeImage from './fade-image';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';
import { ProductSliderContext } from './context';

export default function ProductSlide({
  title,
  subtitle,
  emphasize,
  colorVariant,
  imageObjectMobile,
  imageObject,
  link,
  colorScheme,
  hotspots,
  lastHotspot,
  isBehind = false,
}: Readonly<ProductSlideProps>) {
  const isMobile = useIsMobile();
  const { hotspotOpen, sliderContainerWidth } = useContext(ProductSliderContext);
  const index = isBehind ? 0 : 1;

  const image = isMobile && imageObjectMobile.url ? imageObjectMobile : imageObject;
  let hotspotImage;
  if (hotspotOpen && lastHotspot && index === lastHotspot.imageIndex) {
    hotspotImage =
      isMobile && lastHotspot?.pictureObjectMobile?.url
        ? lastHotspot?.pictureObjectMobile
        : lastHotspot?.pictureObject;
  }

  return (
    <div className={classNameBuilder(styles.imageSlide, isBehind && `${styles.absolute} absolute`)}>
      {!isBehind && (
        <Image data={image} aspectRatio="16_9">
          <FadeImage src={hotspotImage?.url || image.url} alt={hotspotImage?.pictureAlt} />
          {!isEmpty(hotspots) && !isMobile && (
            <HotspotsWrapper
              areas={hotspots}
              colorScheme={colorScheme}
              lastHotspot={lastHotspot}
              imageIndex={index}
              direction={isBehind ? 'right' : 'left'}
              isProductSlider
            />
          )}
        </Image>
      )}
      <div
        className={classNameBuilder(!isBehind && styles.infront, styles.absoluteRow)}
        style={sliderContainerWidth > 0 ? { width: `${sliderContainerWidth}px` } : undefined}
      >
        {isBehind && (
          <>
            <FadeImage src={hotspotImage?.url || image.url} alt={hotspotImage?.pictureAlt} />
            {!isEmpty(hotspots) && !isMobile && (
              <HotspotsWrapper
                areas={hotspots}
                colorScheme={colorScheme}
                lastHotspot={lastHotspot}
                imageIndex={index}
                direction={isBehind ? 'right' : 'left'}
                isProductSlider
              />
            )}
          </>
        )}
        {!isMobile && (
          <div className={classNameBuilder(!isBehind && styles.infront, styles.absolute)}>
            <ProductSlideTitle
              title={title}
              link={link}
              emphasize={emphasize}
              colorVariant={colorVariant}
              subtitle={subtitle}
            />
          </div>
        )}
      </div>
    </div>
  );
}
