import { useState, useEffect, useContext } from 'react';

// types
import type { Hotspot, HotspotsWrapperProps } from './product-slider.types';

// components
import Tooltip from './tooltip';
import Hotspots from '../hotspots/hotspots';
import { useTracking } from 'utils/hooks/useTracking';
import { decodingContent } from 'utils/decodingContent';
import { clickHotspot } from '../hotspots/tracking-actions';
import { ProductSliderContext } from './context';

export default function HotspotsWrapper({
  areas,
  activeHotspot,
  setActiveHotspot,
  lastHotspot,
  imageIndex,
  direction,
  isProductSlider,
  colorScheme,
}: Readonly<HotspotsWrapperProps>) {
  const track = useTracking();
  const { hotspotOpen, setHotspotOpen, onButtonClick, setLastHotspot, tooltip, setTooltip } =
    useContext(ProductSliderContext);
  const [currentTooltip, setCurrentTooltip] = useState(tooltip);

  const onTooltipClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // at this point currentTooltip is always defined - to tell TS we check for undefined
    if (!currentTooltip) return;

    // since currentTooltip is always an item from areas we can safely assume that imageItem is defined
    const imageItem = areas.find((item) => item.tooltip === currentTooltip.tooltip) as Hotspot;

    const svgLeft = parseFloat(currentTooltip.position.left.split('%')[0] ?? '0');
    const overlayPosition = svgLeft <= 50 ? 'right' : 'left';

    if (setActiveHotspot) setActiveHotspot({ ...imageItem, overlayPosition, imageIndex });
    setLastHotspot({ ...imageItem, overlayPosition, imageIndex });
    onButtonClick(e, direction);
    setHotspotOpen(true);
    if (isProductSlider) track.trackEvent(clickHotspot(decodingContent(currentTooltip?.tooltip)));
    setCurrentTooltip(undefined);
  };

  const onLeave = () => {
    setCurrentTooltip(undefined);
  };

  useEffect(() => {
    if (tooltip) {
      setCurrentTooltip(tooltip);
    }
  }, [tooltip]);

  useEffect(() => {
    if (hotspotOpen) setCurrentTooltip(undefined);
  }, [hotspotOpen]);

  return (
    <>
      <Hotspots
        areas={areas}
        colorScheme={colorScheme}
        activeHotspot={activeHotspot}
        setActiveHotspot={setActiveHotspot}
        lastHotspot={lastHotspot}
        setLastHotspot={setLastHotspot}
        hotspotOpen={hotspotOpen}
        setHotspotOpen={setHotspotOpen}
        setTooltip={setTooltip}
        onClick={onButtonClick}
        direction={direction}
        imageIndex={imageIndex}
      />
      <Tooltip
        tooltip={currentTooltip?.tooltip ?? ''}
        top={currentTooltip?.position.top ?? ''}
        left={currentTooltip?.position.left ?? ''}
        onClick={onTooltipClick}
        visible={Boolean(tooltip)}
        onLeave={onLeave}
        setCurrentTooltip={setCurrentTooltip}
        color="grey"
      />
    </>
  );
}
